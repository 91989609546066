<template>
  <el-dialog
    :title="isNew ? 'Cadastrar' : 'Editar' + ' ordem de abastecimento'"
    v-model="shouldShow"
  >
    <form>
      <el-select
        v-model="car_supply_.partner_id"
        filterable
        remote
        clearable
        size="medium"
        reserve-keyword
        placeholder="Digite o nome do fornecedor para buscar"
        :remote-method="fetchProviders"
        :loading="isLoadingSearch"
      >
        <el-option
          v-for="item in Providers"
          :key="item?.uid"
          :label="item?.name"
          :value="item?.uid"
        >
        </el-option>
      </el-select>

      <el-row :gutter="8" type="flex">
        <el-col>
          <el-select v-model="car_supply_.driver_id" size="medium">
            <template #prefix>Motorista:</template>
            <el-option
              v-for="item in Drivers"
              :key="item.uid"
              :label="item?.employee?.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="8" type="flex">
        <el-col>
          <el-select
            v-model="car_supply_.car_id"
            size="medium"
            remote
            :loading="!cars"
            :visible-change="fetchCars"
            loading-text="Carregando veículos..."
            placeholder="Selecione o veículo"
          >
            <template #prefix>Veículo:</template>
            <el-option
              v-for="car in Cars"
              :key="car.uid"
              :label="car.name"
              :value="car.uid"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="8">
        <el-col :md="12">
          <base-input
            :modelValue="`${car_supply_.code || 1}/${
              car_supply_.year_code || new Date().getFullYear()
            }`"
            label="Ordem:"
            placeholder="Automático"
            disabled
          ></base-input>
        </el-col>
        <el-col :md="12">
          <el-date-picker
            v-model="car_supply_.expires_at"
            type="date"
            size="medium"
            placeholder="Data de Validade:"
            :shortcuts="shortcuts"
            format="DD/MM/YYYY"
          >
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row :gutter="8" type="flex">
        <el-col :md="12">
          <el-select v-model="car_supply_.fuel" size="medium" clearable>
            <template #prefix>Combustível:</template>
            <el-option
              v-for="item in Fuels"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :md="12">
          <base-input
            v-model="car_supply_.amount"
            label="Quantidade:"
            type="number"
            placeholder="vazio para completar"
          ></base-input>
        </el-col>
      </el-row>

      <el-row :gutter="8" type="flex">
        <el-col :md="12">
          <el-select v-model="car_supply_.fuel2" size="medium" clearable>
            <template #prefix>Combustível:</template>
            <el-option
              v-for="item in Fuels"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :md="12">
          <base-input
            v-model="car_supply_.amount2"
            label="Quantidade:"
            type="number"
            placeholder="vazio para completar"
          ></base-input>
        </el-col>
      </el-row>

      <el-row :gutter="8" type="flex">
        <el-col :md="12">
          <el-select
            v-model="car_supply_.lubrification"
            size="medium"
            clearable
          >
            <template #prefix>Lubrificação:</template>
            <el-option
              v-for="item in Lubs"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :md="12">
          <base-input
            v-model="car_supply_.lub_amount"
            label="Quantidade:"
            type="number"
            placeholder="vazio para completar"
          ></base-input>
        </el-col>
      </el-row>

      <base-input
        label="Odômetro:"
        hint="1506460"
        type="money"
        :minimumFractionDigits="0"
        v-model="car_supply_.odometer"
      >
      </base-input>
      <el-row type="flex" justify="space-between">
        <h5>Notificar no WhatsApp:</h5>
        <el-switch
          v-model="car_supply_.notify"
          :disabled="!SelectedDriver?.employee?.contact"
          active-text="Sim"
          :active-value="true"
          :inactive-value="false"
          inactive-text="Não"
          active-color="#409EFF"
          inactive-color="#409EFF"
        ></el-switch>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Observações:</h4>
      </el-row>
      <el-input type="textarea" v-model="car_supply_.obs"></el-input>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import PartnerService from "../../services/partners";
import CarService from "../../services/cars";

export default {
  props: ["car", "showModal", "supply"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      car_: this.car || {},
      isLoadingSave: false,
      car_supply_: {},
      isLoadingSearch: false,
      cars: null,
      providers: [],
      drivers: null,
      shortcuts: [
        {
          text: "Hoje",
          value: new Date(),
        },
        {
          text: "Ontem",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "Segunda-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 1)
            );
            return date;
          })(),
        },
        {
          text: "Terça-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 2)
            );
            return date;
          })(),
        },
        {
          text: "Quarta-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 3)
            );
            return date;
          })(),
        },
        {
          text: "Quinta-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 4)
            );
            return date;
          })(),
        },
        {
          text: "Sexta-feira",
          value: (() => {
            const date = new Date();
            date.setTime(
              date.getTime() - 3600 * 1000 * 24 * (date.getDay() - 5)
            );
            return date;
          })(),
        },
      ],
    };
  },
  mounted() {
    this.fetchCars(true);
    this.fetchDrivers();
    this.fetchProviders();
  },
  watch: {
    car(v) {
      this.car_ = v || {};
      if (v?.driver) {
        this.drivers = [v.driver];
        this.car_supply_.driver_id = v?.driver?.uid;
      }
      if (v?.provider) {
        this.providers = [v.provider];
        this.car_supply_.partner_id = v?.provider?.uid;
      }
    },
    supply(v) {
      this.car_supply_ = v || {};
      if (v?.driver) {
        this.drivers = [v.driver];
        this.car_supply_.driver_id = v?.driver?.uid;
      }
      if (v?.provider) {
        this.providers = [v.provider];
        this.car_supply_.partner_id = v?.provider?.uid;
      }
      if (v?.car) {
        this.car_supply_.car_id = v?.car?.uid;
        //this.cars = [v.car];
      }
    },
  },
  computed: {
    Fuels() {
      return [
        { name: "Diesel S10", value: "s10" },
        { name: "Diesel S500", value: "s500" },
        { name: "Gasolina", value: "gasoline" },
        { name: "Álcool", value: "alcohol" },
        { name: "Arla", value: "arla" },
      ];
    },
    Lubs() {
      return [
        { name: "Óleo do Motor", value: "oil" },
        { name: "Graxa", value: "grease" },
      ];
    },
    Cars() {
      return this.cars || [];
    },
    Providers() {
      return this.providers || [];
    },
    Drivers() {
      return this.drivers || [];
    },
    SelectedDriver() {
      return this.Drivers.find((d) => d.uid === this.car_supply_.driver_id);
    },
    SafeAddr() {
      return this?.car_?.addr || {};
    },
    isNew() {
      return !this.supply;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return this.isNew ? this.createCarSupply() : this.updateCarSupply();
    },
    createCarSupply() {
      fetch(`${this.$store.state.apiUrl}cars/${this.car?.uid}/supplies`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.car_supply_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.shouldShow = false;
            this.car_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar o produto",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    fetchDrivers() {
      fetch(`${this.$store.state.apiUrl}drivers`, {
        credentials: "include",
      })
        .then((r) => r.json())
        .then((json) => (this.drivers = json));
    },
    async fetchCars(v) {
      if (v) {
        const { cars } = await CarService().index();

        if (cars) this.cars = cars;
      }
    },
    async fetchProviders(queryName = "") {
      const { partners } = await PartnerService().index({
        limit: 15,
        is_provider: true,
        searchName: queryName,
      });

      if (partners) this.providers = partners;
    },
    updateCarSupply() {
      fetch(
        `${this.$store.state.apiUrl}cars/${this.car?.uid}/supplies/${this.car_supply_?.uid}`,
        {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify(this.car_supply_),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar a ordem",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
  },
  name: "VehicleSupplyOrder",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.el-input__inner {
  padding-right: 0px !important;
}
</style>