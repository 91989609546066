<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row :gutter="8">
        <el-col :md="12">
          <base-input label="Marca:" hint="Volvo" v-model="vehicle_.brand">
          </base-input>
        </el-col>

        <el-col :md="12">
          <base-input label="Modelo:" hint="FH12" v-model="vehicle_.model">
          </base-input>
        </el-col>
      </el-row>

      <base-input
        label="Placa:"
        hint="XRT - 1360"
        :modelValue="vehicle_?.pane"
        @update:modelValue="vehicle_.pane = $event?.toUpperCase()"
      >
      </base-input>

      <base-input
        label="Ano:"
        hint="2012"
        mask="####"
        v-model="vehicle_.year_model"
      >
      </base-input>

      <base-input
        label="Capacidade do tanque"
        hint="500"
        mask="#####"
        v-model="vehicle_.max_quantity"
      >
      </base-input>

      <base-input
        label="Rodagem diária máxima"
        hint="1500"
        mask="#####"
        v-model="vehicle_.max_daily_kilometers"
      >
      </base-input>

      <el-row type="flex" justify="space-between">
        <h5>Pode efetuar entrega?</h5>
        <el-switch
          v-model="vehicle_.can_deliver"
          active-text="Sim"
          :active-value="1"
          :inactive-value="0"
          inactive-text="Não"
          active-color="#409EFF"
          inactive-color="#409EFF"
        ></el-switch>
      </el-row>

      <el-select
        remote
        filterable
        clearable
        v-model="vehicle_.cost_id"
        :remote-method="fetchCosts"
        size="medium"
        value-key="uid"
      >
        <template #prefix>Centro Custo:</template>
        <el-option
          v-for="item in Costs"
          :key="item.uid"
          :label="item.reference"
          :value="item.uid"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["vehicle", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      vehicle_: this.vehicle || {},
      isLoadingSave: false,
      costs: [],
    };
  },
  watch: {
    vehicle(v) {
      this.vehicle_ = v || {};
      if (v?.cost?.length) {
        this.costs = v?.cost;
        this.vehicle_.cost_id = v?.cost[0]?.uid;
      }
    },
  },
  computed: {
    Costs() {
      return this.costs || [];
    },
    ModalTitle() {
      return !this.isNew ? "Editar veículo" : "Cadastrar veículo";
    },
    isNew() {
      return !this.vehicle;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return this.isNew ? this.createVehicle() : this.updateVehicle();
    },
    fetchCosts(v) {
      const url = new URL(`${this.$store.state.apiUrl}costs`);
      url.search = new URLSearchParams({ searchName: v === true ? "" : v });
      if (v)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => {
            this.costs = json;
            this.$emit("fetch-costs", json);
          });
    },
    createVehicle() {
      fetch(`${this.$store.state.apiUrl}cars`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.vehicle_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.$emit("close-modal");
            this.shouldShow = false;
            this.vehicle_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível cadastrar o veículo",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
        });
    },
    updateVehicle() {
      fetch(`${this.$store.state.apiUrl}cars/${this.vehicle_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(this.vehicle_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.$emit("close-modal");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Não foi possível alterar o veículo",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
  },
  name: "VehicleModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>