<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Veículos cadastrados</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openVehicleModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="vehicles"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="pane" label="placa"> </el-table-column>
      <el-table-column prop="brand" label="marca"> </el-table-column>
      <el-table-column prop="model" label="modelo"> </el-table-column>
      <el-table-column prop="year_model" label="ano fabricação">
      </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openVehicleSupplyOrderModal(cat.row)"
              >
                <i class="el-icon-truck"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openVehicleControlModal(cat.row)"
              >
                <i class="el-icon-info"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openVehicleQRcodeModal(cat.row)"
              >
                <i class="el-icon-printer"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openVehicleModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteVehicle(cat.row)"
                title="
              Remover veículo?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <vehicle-modal
      :showModal="showVehicleModal"
      :vehicle="vehicle"
      @close-modal="showVehicleModal = false"
      @should-update="fetchVehicle"
    ></vehicle-modal>
    <vehicle-obs-modal
      :showModal="showVehicleControlModal"
      @close-modal="showVehicleControlModal = false"
      :vehicle="vehicle"
    ></vehicle-obs-modal>
    <vehicle-supply-order
      :showModal="showVehicleSupplyOrder"
      @close-modal="showVehicleSupplyOrder = false"
      :car="vehicle"
    ></vehicle-supply-order>
    <VehicleQRCodeModal
      :showModal="showVehicleQRCodeModal"
      @close-modal="showVehicleQRCodeModal = false"
      :car="vehicle"
    ></VehicleQRCodeModal>
  </el-card>
</template>

<script>
import VehicleModal from "./modals/VehicleModal.vue";
import VehicleObsModal from "./modals/CarControlModal.vue";
import VehicleSupplyOrder from "./modals/VehicleSupplyOrder.vue";
import VehicleQRCodeModal from "./modals/VehicleQRCodeModal.vue";
export default {
  name: "VehiclesPage",
  components: {
    VehicleModal,
    VehicleObsModal,
    VehicleSupplyOrder,
    VehicleQRCodeModal,
  },
  data: () => ({
    hasError: false,
    isLoading: true,
    vehicles: null,
    firm: null,
    vehicle: null,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    showVehicleModal: false,
    showVehicleSupplyOrder: false,
    showVehicleControlModal: false,
    showVehicleQRCodeModal: false,
  }),
  mounted() {
    this.fetchVehicle();
  },
  methods: {
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    deleteVehicle(v) {
      fetch(`${this.$store.state.apiUrl}cars/${v.uid}`, {
        credentials: "include",
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.text();
        })
        .then(() => {
          this.fetchVehicle();
          this.$notify({
            title: "Veículo removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Não foi possível remover o veículo",
            type: "error",
            message: e.message,
            position: "bottom-right",
          });
        });
    },
    openVehicleModal(e) {
      (this.vehicle = e), (this.showVehicleModal = true);
    },
    openVehicleSupplyOrderModal(e) {
      (this.vehicle = e), (this.showVehicleSupplyOrder = true);
    },
    openVehicleControlModal(e) {
      this.vehicle = e;
      this.showVehicleControlModal = true;
    },
    openVehicleQRcodeModal(e) {
      this.vehicle = e;
      this.showVehicleQRCodeModal = true;
    },
    fetchVehicle() {
      fetch(`${this.$store.state.apiUrl}cars`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.vehicles = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>