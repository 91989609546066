<template>
  <el-dialog title="QRCode do Veículo" v-model="shouldShow">
    <el-row type="flex" justify="center">
      <h3>{{ car?.brand }} {{ car?.name }}</h3>
    </el-row>
    <el-row type="flex" justify="center">
      <vue-qr-code :value="car?.uid" :options="{ width: 350 }"></vue-qr-code>
    </el-row>

    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="myPDF"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <template #pdf-content>
        <el-row type="flex" justify="center">
          <h3>{{ car?.brand }} {{ car?.name }}</h3>
        </el-row>
        <el-row type="flex" justify="center">
          <vue-qr-code
            :value="car?.uid"
            :options="{ width: 350 }"
          ></vue-qr-code>
        </el-row>
      </template>
    </VueHtml2pdf>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button type="primary" class="button" @click="generatePDF">
          Gerar PDF
        </el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import VueHtml2pdf from "vue3-html2pdf";
import VueQrCode from "@chenfengyuan/vue-qrcode";

export default {
  props: ["showModal", "car"],
  components: { VueHtml2pdf, VueQrCode },
  emits: ["close-modal", "should-update"],
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  name: "VehicleQRCodeModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>